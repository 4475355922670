import $ from 'jquery'

import Block from '../block'

export default class NumberInput extends Block {
	static title = 'number'

	constructor($el) {
		super($el)

		this.min = Number(this.$el.findElement('input').attr('min') ?? 1)
		this.max = Number(this.$el.findElement('input').attr('max') ?? Infinity)

		if (this.$el.hasModifier('product')) {
			$(document).on('size:change', (_event, data) => {
				if (
					'stock' in data &&
					data.availability != 'available' &&
					data.availability != 'presale'
				) {
					this.max = data.stock || 1
					this.$el.findElement('input').val(1)
				}
			})
		}

		const self = this
		this.$el.findElement('button').click(function () {
			self.handleButtonClick($(this))
		})
	}

	handleButtonClick($button) {
		let value = Number(this.$el.findElement('input').val())
		const selected = $('.size-select__dropdown').find(':selected')

		if ($button.hasModifier('plus')) {
			value = Math.min(this.max, value + 1)

			if (value > selected.data('stockStore') && value != 1) {
				$(document).trigger('size:change', {
					id: selected.val(),
					name: selected.data('title'),
					stock: selected.data('stock'),
					price: selected.data('price'),
					price_advice: selected.data('price-advice'),
					price_save: selected.data('price-save'),
					credit: selected.data('credit'),
					remove: selected.is(':checked'),
					location: 'longtail',
					availability: selected.data('availability'),
					sku: selected.data('sku'),
					outlet: selected.data('outlet'),
					stock_store: selected.data('stockStore'),
				})
			}
		} else {
			value = Math.max(this.min, value - 1)
			if (value <= selected.data('stockStore')) {
				$(document).trigger('size:change', {
					id: selected.val(),
					name: selected.data('title'),
					stock: selected.data('stock'),
					price: selected.data('price'),
					price_advice: selected.data('price-advice'),
					price_save: selected.data('price-save'),
					credit: selected.data('credit'),
					remove: selected.is(':checked'),
					location: selected.data('location'),
					availability: selected.data('availability'),
					sku: selected.data('sku'),
					outlet: selected.data('outlet'),
					stock_store: selected.data('stockStore'),
				})
			}
		}

		this.$el.findElement('input').val(value).trigger('change')
	}
}
