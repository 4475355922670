import $ from 'jquery'

import PrdocutInfo from '../../common.blocks/product-info'

export default class ProductInfo extends PrdocutInfo {
	static title = 'product-info'

	constructor($el) {
		super($el)

		this.price = this.$el.findElement('price-highlight')
		this.credit = this.$el.findElement('credit')
		this.price_advice = this.$el.findElement('price-advice')
		this.price_save = this.$el.findElement('price-save')
		this.price_savings = this.$el.findElement('price-savings')
		this.selectedSize = this.$el.findElement('option--size')
		this.selectedColor = this.$el.findElement('option--color')
		this.stockMessage = this.$el.findElement('message')

		// if an user selects a new color, we want to display the corresponding size select
		$(document).on('size:change', (event, data) =>
			this.handleSizeChange(event, data),
		)

		$(document).on('color:change', (event, data) =>
			this.handleColorChange(event, data),
		)
	}

	changeSize(event, data) {
		super.changeSize(event, data)

		const availability =
			data.availability === 'presale' ||
			data.availability === 'available' ||
			(data.stock && data.availability !== 'available_soon')
		const outlet = !data.outlet && data.stock_store > 0

		if (availability || outlet) {
			this.setCtaAddToBag()
		} else {
			this.setCtaProductNotify()
		}
	}

	handleSizeChange(event, data) {
		if (data.location == 'longtail' || data.stock < 1) {
			this.stockMessage.addModifier('hidden')
		} else {
			this.stockMessage.removeModifier('hidden')
		}
		const title = this.selectedSize.data('title')
		this.selectedSize.text(title + ': ' + data.name)
		this.price.text('€ ' + data.price)
		this.credit.text(data.credit)
		if (data.price_advice > data.price) {
			this.price_advice.removeModifier('hidden')
			this.price_save.removeModifier('hidden')
			this.price_advice.text(data.price_advice)
			this.price_savings.text(data.price_save)
		} else {
			this.price_advice.addModifier('hidden')
			this.price_save.addModifier('hidden')
		}
		$('input[name="ga4-size"]').val(data.id)
	}

	handleColorChange(event, data) {
		const title = this.selectedColor.data('title')
		this.selectedColor.text(title + ': ' + data.name)
		this.price.text('€ ' + data.price)

		$('input[name="ga4-color"]').val(data.id)
	}

	showAddToCart(show) {
		const btn = $('.button--add-to-cart')
		if (show) {
			btn.removeClass('button--hidden')
		} else {
			btn.addClass('button--hidden')
		}
	}

	// set call to action to be the cart button
	setCtaAddToBag() {
		// hide product notify button
		this.$el.find('.notify-popup__trigger').addClass('button--hidden')

		// show cta
		this.$el
			.find('.button--add-to-cart')
			.removeClass('button--hidden button--disabled')
			.removeAttr('disabled')
		// enable number
		this.$el.find('.number--product').removeClass('number--disabled')
	}

	// set call to action to be the product notify popup trigger button
	setCtaProductNotify() {
		// hide atb and disable number
		this.$el.find('.button--add-to-cart').addClass('button--hidden')
		this.$el.find('.number--product').addClass('number--disabled')

		// show cta
		this.$el
			.find('.notify-popup__trigger')
			.removeClass('button--hidden button--disabled')
			.removeAttr('disabled')
	}
}
