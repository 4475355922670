import 'magnific-popup'
import Swiper, { Navigation } from 'swiper'

import Block from '../block'

export default class ProductImages extends Block {
	static title = 'product-images'

	constructor($el) {
		super($el)

		this.iframe = this.$el.findElement('video--iframe')

		if (this.$el.hasModifier('hidden') === false) {
			this.initialize()
		}

		// if an user selects a new color, we want to display the corresponding product images
		$(document).on('color:change', (event, data) =>
			this.handleColorChange(data),
		)
	}

	handleColorChange(data) {
		if (
			this.$el.data('colorId').toString() === data.id ||
			this.$el.data('colorId') == 'static'
		) {
			this.iframe.attr('src', this.iframe.data('video'))
			this.$el.removeModifier('hidden')

			this.initialize()
		} else {
			this.$el.addModifier('hidden')

			this.iframe.attr('src', '')

			// this.destroy()
		}
	}

	/**
	 * Start the carousel and the image lightbox
	 */
	initialize() {
		// initialize the image gallery
		this.$el.findElement('image').magnificPopup({
			type: 'image',
			gallery: {
				enabled: true,
				navigationByImageClick: true,
				preload: [0, 1],
			},

			tLoading: `${this.$el.data('loadingLabel')}...`,
		})

		// initialize the carousel that displays all images for one color
		const carousel = new Swiper(this.$el.findElement('carousel').get(0), {
			slidesPerView: 1,
			wrapperClass: 'product-images__wrapper',
			slideClass: 'product-images__image',
			autoHeight: false,

			pagination: {
				el: this.$el.findElement('bullets'),
				type: 'bullets',
				bulletClass: 'product-images__bullet',
				bulletActiveClass: 'product-images__bullet--active',
				clickable: true,
			},

			modules: [Navigation],

			navigation: {
				nextEl: '.product__next',
				prevEl: '.product__prev',
			},
		})

		this.carousel = carousel

		this.$el.findElement('bullet').click(function () {
			carousel.slideTo($(this).index(), 300)
		})
	}

	/**
	 * Destroys the carousel instance
	 */
	destroy() {
		$.magnificPopup.close()
		if (typeof this.carousel !== 'undefined') {
			this.carousel.destroy(false)
		}
	}
}
